import * as React from "react";
import { FidizeUtils } from '@fidize/module.fidizeutils';
import { TemplateNoVariants } from "../components/template/templateNoVariants";

export default class ProductPage extends React.Component {
    state = {
      product: null
    }

    constructor(props){
      super(props)

      var handle = window.location.href.split("?")[1];
      FidizeUtils.getProductHandle(handle).then((product) => {
        this.setState({product: product})
      })
    }

    render (){
      if(this.state.product == null){
        return <div style={{height: "500px"}}></div>
      }
      return <TemplateNoVariants product={this.state.product} />
    }
}
