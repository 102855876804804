import React from 'react';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Vspace } from "@fidize/ui.vspace"
import { Responsive } from "@fidize/ui.responsive"

export class ShippingTime extends React.Component {

  getShippingDate = () => {
    //Shipping time
  	var holiday = {
      0: [1,6],
      1: [],
      2: [],
      3: [12,13,25],
      4: [1],
      5: [2],
      6: [],
      7: [15],
      8: [],
      9: [],
      10: [1],
      11: [8,25,26]
    };
    var mesi = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ]
  	var oramax = 13;

  	var now = new Date();
  	var shipday = new Date();
  	var from = new Date();
  	var to = new Date();
  	var dayms = 24*60*60*1000;
  	var	from_after = 2;


  	while(shipday.getDay() == 0 | shipday.getDay() == 6 | shipday.getHours() > oramax | ( shipday.getHours() == oramax & shipday.getMinutes() > 0) | holiday[shipday.getMonth()].includes(shipday.getDate())){
    	shipday.setHours(oramax);
		shipday.setMinutes(0);
      	shipday.setTime(shipday.getTime() + dayms);
    }
    shipday.setHours(oramax);
    shipday.setMinutes(0);
    from.setTime(shipday);

  	while(from.getDay() == 6 | from.getDay() == 0 | holiday[from.getMonth()].includes(from.getDate()) | from_after > 0){
    	from.setTime(from.getTime() + dayms);

      	if(from.getDay() != 6 & from.getDay() != 0 & !holiday[from.getMonth()].includes(from.getDate())){
          from_after--;
        }
    }


  	to.setTime(from.getTime() + dayms);
  	while(to.getDay() == 6 | to.getDay() == 0 | holiday[to.getMonth()].includes(to.getDate())){
    	to.setTime(to.getTime() + dayms);
    }

    return from.getDate() + " " + mesi[to.getMonth()];
  }

  render(){
    var desk =  <Flex type="justify_center">
                  <Text type="xs black">Ordina ora e ricevi entro il <Text type="xs black bold">{this.getShippingDate()}</Text></Text>
                </Flex>

    if(this.props.hasOwnProperty("version") & this.props.version == 2){
        desk =  <Flex type="justify_center">
                  <Text type="xs black">Ordina ora, completa la personalizzazione e ricevi entro il <Text type="xs black bold">{this.getShippingDate()}</Text></Text>
                </Flex>
    }

    var mobile = desk

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}
