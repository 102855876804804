import React from 'react';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css/pagination';
import styles from './bigGallery.module.scss'
import { Responsive } from "@fidize/ui.responsive"

export class BigGallery extends React.Component {
  gallery = null;
  thumbs = null;

  constructor(props){
    super(props);
  }

  selectSlide = (id) => {
    if(this.gallery != null){
      this.gallery.slideTo(id, 700);
    }
  }

  selectThums = (id) => {
    if(this.gallery != null){
      this.gallery.slideTo(id, 700);
    }
  }

  render(){
    var params = {
      spaceBetween: 25,
      slidesPerView: 1,
      onSwiper: (swiper) => {this.gallery = this.gallery != null ? this.gallery : swiper}
    }

    var desk =  <div className={styles.biggalleryContainer}>
                  <Swiper {...params}>
                      {
                        this.props.product.images.map((img) => {
                          return  <SwiperSlide>
                                    <img src={img.src} className={styles.biggalleryImg}/>
                                  </SwiperSlide>
                        })
                      }

                    </Swiper>
                    <Swiper
                      spaceBetween={25}
                      slidesPerView={5}
                      onSwiper={(swiper) => {this.thumbs = this.thumbs != null ? this.thumbs : swiper}}
                      >
                        {
                          this.props.product.images.map((img, id) => {
                            return  <SwiperSlide>
                                      <img src={img.src} className={styles.biggalleryThumb} index={id} onClick={(e) => this.selectSlide(id)} />
                                    </SwiperSlide>
                          })
                        }
                      </Swiper>
                </div>

    var paramsMobile = {
      spaceBetween: 25,
      slidesPerView: 1,
      pagination: {
        el: "." + styles['paginationMobile'],
        clickable: true
      },
      modules: [Pagination]
    }
    var mobile =  <div className={styles.biggalleryContainer}>
                    <Swiper {...paramsMobile}>
                        {
                          this.props.product.images.map((img) => {
                            return  <SwiperSlide>
                                      <img src={img.src} className={styles.biggalleryImg}/>
                                    </SwiperSlide>
                          })
                        }

                        <div className={styles['paginationMobile']}></div>
                      </Swiper>
                    </div>

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}
