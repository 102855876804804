import React from 'react';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Vspace } from "@fidize/ui.vspace"
import { Responsive } from "@fidize/ui.responsive"
import { Hpadding } from "@fidize/ui.hpadding"
import { ShippingTime } from "../../shippingTime"
import { ExtraSpecs } from "../../extraSpecs"
import { Description } from "../../description"
import { BigGallery } from "../../bigGallery"

export class TemplateNoVariants extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    var desk =  <Hpadding padding="1">
                  <ShippingTime version={2} />
                  <ExtraSpecs />
                  <Description product={this.props.product} />
                  <BigGallery product={this.props.product} />

                </Hpadding>

    var mobile = <div>
                <BigGallery product={this.props.product} />
              </div>

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}
