import React from 'react';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Vspace } from "@fidize/ui.vspace"
import { Responsive } from "@fidize/ui.responsive"
import { Icon } from "@fidize/ui.icon"
import styles from './extraSpecs.module.scss';

export class ExtraSpecs extends React.Component {

  render(){
    var desk =  <Flex type="justify_spacebetween">
                  <Flex type="column">
                    <Flex type="justify_center">
                      <Text type="h1 black">
                        <Icon>local_shipping</Icon>
                      </Text>
                    </Flex>
                    <Flex type="justify_center">
                      <Text type="normal black">Spedizione</Text>
                    </Flex>
                    <Flex type="justify_center">
                      <Text type="normal black">espressa</Text>
                    </Flex>
                  </Flex>

                  <Flex className={styles.item} type="column">
                    <Flex type="justify_center">
                      <Text type="h1 black">
                        <Icon>payment</Icon>
                      </Text>
                    </Flex>
                    <Flex type="justify_center">
                      <Text type="normal black">Pagamento</Text>
                    </Flex>
                    <Flex type="justify_center">
                      <Text type="normal black">100% sicuro</Text>
                    </Flex>
                  </Flex>

                  <Flex className={styles.item} type="column">
                    <Flex type="justify_center">
                      <Text type="h1 black">
                        <Icon>headset_mic</Icon>
                      </Text>
                    </Flex>
                    <Flex type="justify_center">
                      <Text type="normal black">Assistenza</Text>
                    </Flex>
                    <Flex type="justify_center">
                      <Text type="normal black">post-vendita</Text>
                    </Flex>
                  </Flex>
                </Flex>

    var mobile = desk

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}
