import React from 'react';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from "@fidize/ui.responsive"

export class Description extends React.Component {
  constructor(props){
    super(props);

  }

  render(){
    var desk =  <Text type="normal black">
                  <div dangerouslySetInnerHTML={{__html: this.props.product.descriptionHtml}}></div>
                </Text>

    var mobile =  <div>
                    mondo
                  </div>

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}
